<template>
  <router-link class="cta-pregnant" to="/mi-lista">
    <div class="cta-pregnant__left">
      <p class="cta-pregnant__title">
        Haz tu lista de nacimiento con cualquier cosa de cualquier tienda
      </p>
      <div class="cta-pregnant__actions">
        <button class="button button--accent button--block-sm">Empieza ahora</button>
      </div>
    </div>

    <div class="cta-pregnant__cover">
      <img
        loading="lazy"
        class="d"
        src="../../assets/img/covers/cover-cta-happy-pregnant.png"
        alt=""
      />
      <img
        loading="lazy"
        class="m"
        src="../../assets/img/covers/cover-cta-happy-pregnant-m.png"
        alt=""
      />
    </div>
  </router-link>
</template>
<script>
export default {
  name: "CtaPregnant",
};
</script>
